import React from 'react'
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import ContactBar from '../../components/ContactBar';
import SingleImage from '../../library/SingleImage';
import Layout from '../../components/Layout';

const SchroepfenPage = (props) => (
  <Layout location={props.location}>
    <div className="page offer">
      <Helmet>
        <title>Angebot, Behandlungsmethoden</title>
        <meta name="description" content="Akupunktur, Kräutermedizin, Moxa, Schröpfen, Mein Angebot, Behandlungsmethoden" />
        <meta property="og:title" content="Angebot, Behandlungsmethoden" />
        <meta property="og:description" content="Akupunktur, Kräutermedizin, Moxa, Schröpfen, Mein Angebot, Behandlungsmethoden" />
        {/* <meta name="keywords" content="KEYWORDs§" /> */}
      </Helmet>
      <ContactBar className="large-screen" />
      <div className="container">
        <h1>Schröpfen</h1>
        <SingleImage image={props.data.schroepfen.fluid} />
        <p>Schröpfen ist eine seit Jahren angewandte Therapiemethode. Bei dieser Therapiemethode werden verschieden grosse Schröpfgefässe (Glaskugeln) auf Akupunkturpunkte oder Muskelpartien gesetzt. Durch Erhitzen, oder eine Handpumpe, wird die Luft in den Glaskugeln entfernt und ein Vakuum erzeugt, dies bewirkt auf der geschröpften Körperstelle einen Saugreiz  der den Fluss von Qi, Blut und Lymphflüssigkeit aktiviert.</p>
        <p>Schröpfen kann gut eingesetzt werden um Muskelverspannungen zu lösen, krankmachende Faktoren wie Kälte, Feuchtigkeit oder Hitze auszuleiten und das Immunsystem zu stärken.</p>
        <p>Diese Methode wird in Kombination mit anderen Behandlungsstrategien angewendet. Ihr Therapieplan wird individuell zusammengestellt.</p>
      </div>
    </div>
  </Layout>
)

export default SchroepfenPage;

export const query = graphql`
  query SchroepfenImageQuery {
    schroepfen: imageSharp(fluid: { originalName: {regex: "/schroepfen/" }}) {
      fluid(maxHeight: 580, cropFocus: ENTROPY ) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;
